import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import reportWebVitals from './reportWebVitals';
import App from './App';
import { Auth0Provider } from '@auth0/auth0-react';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);


root.render(

  <React.StrictMode>
    <Auth0Provider
    domain="account.expeditions.com"
    clientId="JPyygmG7DPdnNQd5JiSjYISD1Y78gTqW"
    issuer="https://account.expeditions.com/"
    authorizationParams={{
      redirect_uri: window.location.origin + "/paymentrecords",
      audience: "https://link-generator-api.prod.expeditions.lindblad.cloud/",
      scope: 'openid profile read:payments email'
    }}
  >
    <App />
  </Auth0Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
